import { useBoolean } from 'ahooks';
import LoginSignUpModal from '~/modules/user/component/login-signup-modal/LoginSignUpModal';
import type { AuthSource } from '~/modules/user/type';

interface UseLoginModalOptions {
    customRedirect?: string;
    allowExternalUrls?: boolean;
    skipPostSignUp?: boolean;
    authSource: AuthSource;
    subtitle?: string;
}

/**
 * @deprecated Please use useAuth instead, detail check packages/web/app/modules/product/component/downloads/Download.tsx
 * @param param0
 * @returns
 */
export const useLoginModal = ({
    customRedirect,
    allowExternalUrls,
    skipPostSignUp = false,
    authSource,
    subtitle,
}: UseLoginModalOptions) => {
    const [showSignIn, setShowSignIn] = useBoolean();

    const showLoginModal = () => setShowSignIn.setTrue();
    const hideLoginModal = () => setShowSignIn.setFalse();

    return {
        showLoginModal,
        hideLoginModal,
        loginModal: (
            <LoginSignUpModal
                login={{
                    open: showSignIn,
                    onCancel: setShowSignIn.setFalse,
                    onConfirm: setShowSignIn.setFalse,
                    onSuccess: setShowSignIn.setFalse,
                    onAuthSuccess: setShowSignIn.setFalse,
                    flow: null,
                    redirect: customRedirect,
                    allowExternalUrls: allowExternalUrls,
                    skipPostSignUp,
                    authSource,
                    subtitle,
                }}
                forgotPassword={{
                    open: showSignIn,
                    onCancel: setShowSignIn.setFalse,
                }}
            />
        ),
    };
};
